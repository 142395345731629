
import axios from "axios";
const url = require('url');



console.log("REACT_APP_ENVIROMENT", process.env.REACT_APP_ENVIROMENT)
var API_ENDPOINT;
var IMAGE_ENDPOINT;
if (process.env.REACT_APP_ENVIROMENT === "staging") {
  API_ENDPOINT = 'https://app.recsportskw.com';
  IMAGE_ENDPOINT = 'https://recsports-app.s3.us-east-2.amazonaws.com/';
} else if (process.env.REACT_APP_ENVIROMENT === "production") {
  API_ENDPOINT = 'https://app.recsportskw.com';
  IMAGE_ENDPOINT = 'https://recsports-app.s3.us-east-2.amazonaws.com/';
} else {
  API_ENDPOINT = 'http://localhost:5000';
  IMAGE_ENDPOINT = 'https://recsports-app.s3.us-east-2.amazonaws.com/';

}
export const IMAGE_URL = IMAGE_ENDPOINT
export const API_URL = API_ENDPOINT;


export const UCASE = ([first, ...rest], locale = navigator.language) =>
  first.toLocaleUpperCase(locale) + rest.join('')



export const STATUS_OPTION = [{ label: "Active", value: "Active" }, { label: "De-Active", value: "De-Active" }]


export const maxSizeInBytes = 10 * 1024 * 1024; // 10MB maximum file size



export const uploadFileServer = async (data) => {
  try {
    var response = await axios.post(`/upload/s3`, data)
    return response.data.file
  } catch (err) {
    console.log("error", err)
    return null
  }

}


export const fixUrl = (url) => {
  const prefix = 'https://';
  const parts = url.split(prefix);
  if (parts.length >= 3) {
    return prefix + parts[parts.length - 1];
  } else {
    return url;
  }
}
export const googleMapURLRegex = /^https:\/\/maps\.app\.goo\.gl\/[A-Za-z0-9]+$/;

export const getLatLong = async (shortUrl) => {
  try {
    let redirectUrl = shortUrl;
    let maxRedirects = 5; // Set the maximum number of redirects you want to follow
    let redirectsFollowed = 0;
    let response;

    while (redirectsFollowed < maxRedirects) {
      response = await fetch(redirectUrl, {
        method: 'GET',
        redirect: 'manual'
      });

      if (response.status >= 300 && response.status < 400) {
        const location = response.headers.get('location');
        if (location) {
          redirectUrl = location;
          redirectsFollowed++;
        } else {
          throw new Error('Redirect location not found');
        }
      } else {
        break;
      }
    }

    if (!response.ok) {
      throw new Error('Failed to follow redirects');
    }

    const finalUrl = response.url;

    // Parse the final URL to extract lat and long
    const parsedUrl = url.parse(finalUrl, true);
    const query = parsedUrl.query;

    if (query && query.q) {
      // Coordinates are typically in the 'q' parameter
      const coords = query.q.split(',');
      const latitude = parseFloat(coords[0]);
      const longitude = parseFloat(coords[1]);
      return { latitude, longitude };
    } else {
      throw new Error('Coordinates not found in the URL');
    }
  } catch (error) {
    console.error('Error fetching coordinates:', error);
  }
}

export const checkTeamsUniqueAndNonEmpty = (matches) => {
  const teamSet = new Set();

  for (let match of matches) {
    if (!match.team1 || !match.team2 || match.team1 === match.team2) {
      return false; // If any team1 or team2 is null, empty, or the same within the object
    }
    if (teamSet.has(match.team1) || teamSet.has(match.team2)) {
      return false; // If any team1 or team2 is not unique across all objects
    }
    teamSet.add(match.team1);
    teamSet.add(match.team2);
  }

  return true;
}