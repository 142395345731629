import { createSlice } from '@reduxjs/toolkit'

export const errorSlice = createSlice({
    name: 'errors',
    initialState: {
        error: {}
    },
    reducers: {
        geterrors: (state, action) => {
            state.error = action.payload
        },
        setError: (state, action) => {
            state = action.payload
        },
        clearerror: (state, action) => {
            return {
                error: {}
            }
        },
    },
})

export const { geterrors, clearerror, setError } = errorSlice.actions


export default errorSlice.reducer