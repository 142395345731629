import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./reducers/authReducer"
import errorReducer from "./reducers/errorReducer"
import crudReducer from "./reducers/crudReducer"


const store = configureStore({
    reducer: {
        auth: authReducer,
        errors: errorReducer,
        crud: crudReducer,
    },
})
export default store;