import React from 'react'
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const PrivateRoute = (props) => {
    const auth = useSelector(state => state.auth);
    return auth.isAuthenticated === true && auth.user.userType === "admin" ? <Outlet /> : <Navigate to="/admin/login" />
};



export default PrivateRoute;
